import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import "./Experience.css";

const experiences = [
  {
    date: "September 2024 - Present",
    role: "Solutions Engineer",
    company: "Capital Group",
    description: `Partner with cross-functional teams to design and implement software solutions for various business needs.`,
  },
  {
    date: "October 2023 - May 2024",
    role: "Research Assistant",
    company:
      "California State University, Fullerton - Department of Computer Science",
    description: `Developed an AI-driven storytelling system using OpenAI's GPT API, Django, ReactJS, and AWS. 
    Designed and implemented APIs that enable users to generate interactive stories. 
    Developed an algorithm to process and transform responses from OpenAI's GPT into a structured tree format, 
    enhancing user interaction with the narrative.`,
  },
  {
    date: "March 2023 - October 2023",
    role: "STEM Instructor/Mentor",
    company: "Dreams For Schools",
    description: `Introduced 7th and 8th-grade students to the fundamentals of Computer Science Principles. 
    Led them in the development of a mobile application using Agile methodologies, preparing them to compete in the AppJamboree hosted by Dreams For Schools. 
    Maintained consistent communication with program coordinators regarding classroom activities and project progress. 
    Received the State of California Senate - Certificate of Recognition for exceptional mentorship in the Placentia-Yorba Linda Unified School District's “Dreams for Schools” academic program.`,
  },
];

const Experience = () => {
  const controls = useAnimation();
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [controls]);

  const experienceVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
  };

  return (
    <section id="experience" ref={sectionRef}>
      <h2 className="font-bold text-2xl py-10 text-center">Experience</h2>
      <div className="timeline">
        {experiences.map((exp, index) => (
          <motion.div
            key={index}
            className={`experience-container ${
              index % 2 === 0 ? "left" : "right"
            }`}
            initial="hidden"
            animate={controls}
            variants={experienceVariants}
          >
            <div className="experience-content">
              <div className={`date-container`}>
                <p className="date">{exp.date}</p>
              </div>
              <div className="experience-header">
                <div className="timeline-dot"></div>
              </div>
              <div className="experience-details">
                <p className="font-semibold company">{exp.company}</p>
                <h4 className="text-xl font-semibold role">{exp.role}</h4>
                <h6 className="text-neutral-500 dark:text-neutral-300 text-left mt-6 description">
                  {exp.description}
                </h6>
              </div>
            </div>
          </motion.div>
        ))}
        <div className="timeline-center-line"></div>
      </div>
    </section>
  );
};

export default Experience;
